@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~react-image-gallery/styles/css/image-gallery.css";


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'SCoreDream', 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FFFFFF;
  overflow-x: hidden;
}

#root {
  width: 100%;
  font-family: 'Pretendard';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.form-control {
  background-color : white;
}
.sh-bg-dashboard{
  background-color: #1F1F23;
}
